// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";

html {
  overflow: hidden;
}

.hero {
  width: 100vw;
  height: 100vh;
}

.mt-n2 {
  margin-top: -2rem !important;
}

.w-33 {
  width: 33.3333333333% !important;
}